.bridge2{
    width: 80%;
    /* height: 200%; */
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: top;
    flex-direction: row-reverse;
    padding: 60px 10%;
    z-index: 1;

}

.bridge2_head{
    font-size: 2.7rem;
    font-weight: 700;
    font-family: 'poppins', sans-serif;
    color: #2e4742;
    line-height: 3rem;
    flex: 2;
    text-align: right;
    padding-top: 30px;
}

.bridge2_head img {
    width: 70%;
}

.bridge2_content{
    flex:3;
    font-family: 'poppins', sans-serif;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: left;
    color: #2e4742;
}

.bridge2_content h3{
    font-size: 1.2rem;
    font-weight: 700;
    /* padding-bottom: 10px; */
}

.bridge2_content p{
    font-size: 14px;
    padding-bottom: 20px;
}