@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Cormorant+Upright:wght@100;400;500;600;700&display=swap');

.navbar, .navbar2 {
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  
  /* padding: 0 20px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  height: 100px;
  width: 100vw;
  font-family: 'Roboto', sans-serif;
  position: fixed;
  z-index: 100;
  transition: background-color 0.5s ease; /* Adiciona transição para a cor de fundo */
  border-bottom: #33333317 1px solid;
}

.navbar {
  background-color: transparent; /* Cor de fundo inicial */
  color: white
  
}

.navbar2 {
  background-color: rgb(255, 255, 255); /* Nova cor de fundo */
  color:#e55e5e
}
  
  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
     padding: 0 20px ;
    margin: 0 auto; /* Adiciona margem automática para centralizar */
    box-sizing: border-box;
  }
  .sanduiche{
    width: 136px;
    text-align: left;
  }
  .navbar-logo {
    display: flex;
    align-items: center;
    color: white; /* ajuste para a cor do logotipo desejada */
    text-decoration: none; /* remove o sublinhado do link */
    font-size: 34px; /* tamanho da fonte do logotipo */
    font-weight: 300; /* torna o logotipo em negrito */
    font-family: 'Michroma', sans-serif;
    
  }

  .navbar2 .navbar-logo {
    color: #e55e5e; /* ajuste para a cor do logotipo desejada */
  }
  
  .menu-icon{
    font-size: 20px;
  }

  .menu-icon img{
    width: 50px;
    padding-left: 10px;
    filter: brightness(0) invert(1);
  }

  .navbar2 .menu-icon img{
    filter: brightness(1) invert(0.8) hue-rotate(230deg);
  }

  .navbar-logo img {
    height: 50px; /* ajuste conforme o tamanho desejado para o logotipo */
 /* espaço entre o logotipo e o texto, se aplicável */
  }
  
  /* Estilos para os links do Navbar */
  .nav-link {
    text-decoration: none;
    color: #333; /* ajuste para a cor do texto dos links */

    padding: 20px 25px;
    border-radius: 10px; /* raio da borda para os botões arredondados */
    font-size: 14px;
  }
  
  .nav-link:hover {
    background-color: #f2f2f2; /* cor de fundo ao passar o mouse */
  }
  
  /* Estilos para botões ou links especiais como 'Contact us' */
  .nav-link-special {
    background-color: #ff6b6b; /* ajuste para a cor de fundo do botão */
    color: #fff; /* cor do texto do botão */
    text-transform: uppercase; /* texto em maiúsculas */
    font-weight: bold; /* texto em negrito */
  }
  
  .nav-link-special:hover {
    background-color: #e55e5e; /* cor de fundo ao passar o mouse */
  }
  
  .popup{
    /* display: none; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    
  }

  .popup form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 70px 70px 90px 70px;
    background-color: white;
    border-radius: 3px;
    font-family: 'Roboto', sans-serif;
  }

  .popup button{
    background-color: #e55e5e;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 20px;
    transition: background-color 0.3s ease; /* Adiciona transição para a cor de fundo */
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
  
  }

  .popup button:hover{
    background-color: #ff6b6b;
  }

  .popup form h2{
    font-size: 2rem;
    color: #e55e5e;
    margin: 0;
    padding-bottom: 20px;
    font-family: 'Michroma', sans-serif;
  }

  .popup input{
    padding: 10px 20px;
    border: 1px solid #33333317;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
  }

  .popup select{
    padding: 10px 20px;
    border: 1px solid #33333317;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
  }

  .popup textarea{
    padding: 10px 20px;
    border: 1px solid #33333317;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
  }
  
  /* responsive */
  @media (max-width: 768px) {
    .navbar {
      padding: 0 10px; /* ajuste para o preenchimento do Navbar */
    }
    
    .navbar-container {
   
      justify-content: center; /* ajuste para o espaçamento entre os itens */
    }
    
    .menu {
      display: block; /* exibe o ícone do menu */
      width: 300px;
    }

    .navbar-logo{
      font-size: 20px;
    }

    .nav-link-special{
      font-size: 10px;
    }

    .popup form{
      transform: scale(0.8);
    }

    .popup form h2{
      font-size: 1.5rem;
    }

    .popup input{
      padding: 10px 10px;
      font-size: 0.8rem;
    }

    .popup select{
      padding: 10px 10px;
      font-size: 0.8rem;
    }

    .popup textarea{
      padding: 10px 10px;
      font-size: 0.8rem;
    }

    .popup button{
      padding: 10px 10px;
      font-size: 1rem;
      margin-top: 0;
    }
  }