
.Features{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #7b9880; */
    /* background gradient */
    background: linear-gradient(150deg, rgb(215, 106, 106) 0%,rgb(154, 101, 101) 50%, rgb(243, 120, 120) 100%);
    color:white;
    width: 100vw;
}

.Features h1{
    font-size: 3.5rem;
    margin-top: 4.5rem;
    margin-bottom: 2.5rem;
    color: white;
    font-family: 'Michroma', sans-serif;

}
.Features img{
    width: 100%;

}

.tailored{
    padding-bottom: 50px;
    font-size: 32px;
    color:#2f3131;
/* text-shadow: 0 0 20px #2e4742 ; */
    font-family: 'Michroma', sans-serif;
}

.Features p{
    font-size: 20px;
}

.demos{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    margin-top: 3rem;
    gap:50px;
    align-items: top;
    text-align: left;
    padding: 0 20px;
    padding-bottom: 3rem;
    font-size: 30px;
}

.demos h2{
    font-size: 30px;
    margin-bottom: 1.8rem;
    padding-left: 10px;
    width: 300px;
}

.demos p{
    cursor: pointer;
    padding: 10px 10px;
    border-radius: 10px;
    font-size: 20px;
}

.demos p:hover{
background-color: #cccccc;
color:#e55e5e;
font-weight: bold;
}

.demos img{
    width: 50vw;
    height: 50vh;
    max-width: 400px;
    max-height: 400px;
    object-fit: cover;
    /* border-radius: 10px; */
    box-shadow: 0 0 10px 5px #33333317;
    margin-left: 20px;
}

@media (max-width: 768px) {
    .Features{
        padding-bottom: 50px;
    }
    .demos{
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }
    .demos h2{
        width: 100%;
        text-align: center;
    }
    .demos img{
        width: 100%;
        height: 100%;
        margin-left: 0;
    }
    .demos p{
        padding: 2px 10px;
    }
    .tailored{
        text-align: center;
    }
    .Features h1{
        font-size: 2.5rem;
    }
    .Features p{
        font-size: 1.5rem;
    }
}