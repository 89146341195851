    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');



.hero-section {
    /* background-image: url('../images/1.png'); */
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: rgb(0, 0, 0);
    font-family: 'Poppins', sans-serif;
    line-height: 1.2;
    /* top:70px; */
    position: relative;
    width: 100vw;
/* linear gradient background */
background: linear-gradient(65deg, rgba(233, 10, 10, 0.826), rgba(171, 125, 125, 0.5), rgba(250, 186, 186, 0.5));
background-size: cover;
background-position: center;
background-attachment: fixed;
background-repeat: no-repeat;
backdrop-filter: blur(10px); /* Aplica o desfoque para o efeito de vidro */
-webkit-backdrop-filter: blur(10px); /* Prefixo para Safari */
border: 1px solid rgba(255, 255, 255, 0.18); /* Opcional: borda para realçar o efeito */


}

.hero-content {
    max-width: 700px;
    /* margin: 0 auto 200px 80px; */
    padding: 20px;
    /* text-shadow: #898989 1px 0px 20px; */

}

.hero-content h1 {
    font-size: 3rem;
    color: #ffffff;
    margin:0;
    font-family: 'Michroma', sans-serif;
    font-weight: 300;

}   

.hero-content p {
    font-size: 1.9rem;
    /* margin-bottom: 1rem; */
    color: #ffffff;
    margin:0;
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    /* font-family: 'Audiowide', serif; */

}

.demo-button {
    background-color: #ff6b6b;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 20px;
}

.demo-button:hover {
    background-color: #e55e5e;
}


.transition{
    transition: top 1.0s ease-in-out; /* Ajusta conforme necessário */
}

.herobackground video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* filter: blur(5px);
    -webkit-filter: blur(5px);
    opacity: 0.5; */
}

.herobackground{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    transition: top 1s ease, right 1s ease, width 1s ease, height 1s ease; 
}

.herobackground video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: width 1s ease, height 1s ease, margin-left 1s ease; /* Adiciona transição para marginLeft */
}

.homelane{
    position: fixed;
    font-family: 'Michroma', sans-serif;
    font-size: 2rem;
    color:#e55e5e;
    font-weight: 300;
    /* top: 600px; */
    left: -400px;
    transition: all 1s ease;
    z-index: -2;
}

.homelane img{
    position: inherit;
    width: 200px;
}

.show{
top: 450px;
left: 10vw;
}