.bridge{
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    min-height: 200px;
    margin: 0 ;
    gap:5px;
    width: 100vw;
    transition: all 3.5s ease;
    background-color: white;

}

.bridge_head{
    flex:1;
    font-size: 1.7rem;
    font-weight: 700;
    font-family: 'Michroma', sans-serif;
    color: #2e4742;
}

.bridge_content{
    flex: 2;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    padding: 40px;
}

.bridge_content h1{
    text-align: center;
    color: #e55e5e;
    font-family: 'Michroma', sans-serif;
    font-weight: 900;
    font-size: 30px;
}

.box{
    display: flex;
    flex-direction: row;
}

@keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .slide-up-enter {
    animation: slideUp 1.5s forwards ;
  }

  @media (max-width: 768px) {
    .bridge{
        width: 100vw;
        flex-direction: column;
    }
    .bridge_head{
        font-size: 1.5rem;
    }
    .bridge_content{
        font-size: 18px;
padding: 10px;
    }
    .bridge_content h1{
        font-size: 25px;
    }
  }
