.Footer{
    background-color: #f8f9fa;
    padding: 0 10px;
    /* text-align: center; */
    position: relative;
    /* bottom: 0; */
    width: 100vw;
    /* height: 60px; */
    /* line-height: 60px; */
    color: #6c757d;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    /* flex-direction: column; */
    align-items: flex-end;
    gap: 10px;
    height: 130px;
}

.footer-content{
    display: flex;
    flex-direction: row;
    gap: 90px;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 40px;

}

.footer-content a{
    color: #6c757d;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-content a:hover{
    color: #e55e5e;
}

.footer-content p{
    margin: 0;
}

.footer-content p:hover{
    color: #e55e5e;
}

.footer-logo{
    font-size: 8px;
    font-weight: 100;
    font-family: 'Michroma', sans-serif;
}

.footer-social{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

@media (max-width: 768px) {
    .Footer{
        flex-direction: column;
        align-items: center;
        gap: 20px;
        height: 200px;
    }

    .footer-content{
        flex-direction: column-reverse;
        gap: 20px;
        padding-bottom: 0;
        padding-top: 30px;
    }

    .footer-social{
        gap: 10px;
    }
}